import type { Nullable, OptionalString } from '@amzn/elevate-data-types';
import { ConsoleLogger } from 'aws-amplify/utils';
import type { GraphQLError } from 'graphql';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef } from 'react';

const logger = new ConsoleLogger('utils');

const VALID_URL_REGEX = new RegExp(String.raw`(https?://)?([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*/?`);
const VALID_HIRE_URL_REGEX = new RegExp('(http|https)://hire.(corp.|)amazon.com/.*$');

export function urlFormatValidation(url: OptionalString): boolean {
  return VALID_URL_REGEX.test(url ?? '');
}

export function hireUrlFormatValidation(url: OptionalString): boolean {
  return VALID_HIRE_URL_REGEX.test(url ?? '');
}

export function listsMatch<T extends object>(records1: T[], records2: T[], key: keyof T): boolean {
  if (records1.length !== records2.length) return false;
  const recordIds1 = records1.map((record) => record[key]);
  const recordIds2 = records2.map((record) => record[key]);
  return recordIds1.every((ptxId) => recordIds2.includes(ptxId)) && recordIds2.every((ptxId) => recordIds1.includes(ptxId));
}

export function idListsMatch(itemIds1: string[], itemIds2: string[]) {
  if (itemIds1.length !== itemIds2.length) return false;
  return itemIds1.every((id) => itemIds2.includes(id)) && itemIds2.every((id) => itemIds1.includes(id));
}



export function useStableValue<T>(value: T) {
  const ref = useRef<T>(value);

  const isIdentical = ref.current && isEqual(value, ref.current);

  useEffect(() => {
    if (!isIdentical) ref.current = value;
  }, [isIdentical, value]);

  return isIdentical ? ref.current : value;
}

export function getErrorMessageFromException(ex: unknown): string {
  let errorMsg: string;
  if (typeof ex === 'object' && ex && 'errors' in ex) {
    errorMsg = Array.isArray(ex.errors) ? (ex.errors as GraphQLError[])[0].message || '' : '';
  } else {
    errorMsg = JSON.stringify(ex);
  }
  // For local dev work log all the things
  if (import.meta.env.MODE === 'development') logger.info(ex);
  return errorMsg;
}

export function coerceNumber(value: Nullable<string | number>, fallback = 0): number {
  const newValue = typeof value === 'number' ? value : Number.parseInt(value ?? '');
  return Number.isNaN(newValue) ? fallback : newValue;
}

export function boundedNumber(value: number, min: Nullable<string | number>, max: Nullable<string | number>): number {
  const minValue = coerceNumber(min, 0);
  const maxValue = coerceNumber(max, Number.MAX_SAFE_INTEGER);
  // A+ to CodeWhisperer for thinking of this before I did
  return Math.min(Math.max(value, minValue), maxValue);
}

export function cleanPermissionGroup(value: string): string {
  return value.trim().replace('amzn1.abacus.team.', '');
}
