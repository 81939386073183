import { dedupeList, type Nullable, type OptionalString } from '@amzn/elevate-data-types';

import { amplifyFetch } from '@/common/data-provider';
import { InterviewerView } from '@/common/models';
import { cleanPermissionGroup } from '@/common/utility';
import { processEventAvailability } from '@/components/common/event/data-helpers';
import type { EventAvailabilityItem } from '@/components/common/event/models';
import type { CalibrationState } from '@/components/EventCalibration/models';
import {
  getActiveProfileAvailabilitiesByOrg,
  getProfileByAliasClassic,
  getProfileByAliasPlatform,
  getProfileCalibrations,
  getProfileCalibrationsByConfig,
  getProfilesByOrgAndRole,
  getProfilesWithCalibration,
  getProfileUsersUnderShepherd,
  listProfilesByManager,
} from '@/graphql/queries';
import {
  gql,
  type ModelProfileCalibrationStatusFilterInput,
  type ModelProfileOrganizationV2FilterInput,
  OrganizationStatus,
  ProfileStatus,
  Roles,
} from '@/graphql/types';
import type {
  InterviewerProfileItem,
  ProfileSelectOption,
  UserProfile,
  UserProfileClassic,
  UserProfilePlatform,
} from '@/models';
import featureFlags from '@/utilities/feature-flags';

async function getOrgInterviewersByManager(managerAlias: OptionalString) {
  if (!managerAlias) return [];
  const results = await amplifyFetch({
    document: gql(listProfilesByManager),
    variables: { managerAlias },
    onFetchNextToken: (v) => v.listProfilesByManager.nextToken || null,
  });
  return results.flatMap((result) => result.listProfilesByManager.items ?? []);
}

async function getOrgInterviewersByShepherd(orgID: string, id: OptionalString) {
  if (!id) return [];
  const results = await amplifyFetch({
    document: gql(getProfileUsersUnderShepherd),
    variables: { id, orgID },
    onFetchNextToken: (v) => v.getProfile.usersUnderShepherd?.nextToken || null,
  });
  return results
    .flatMap((result) => result.getProfile.usersUnderShepherd?.items ?? [])
    .map((item) => item.userProfile)
    .filter((profile) => profile !== null);
}

async function getOrgInterviewersByOrg(orgID: string) {
  const results = await amplifyFetch({
    document: gql(getProfilesWithCalibration),
    variables: { orgID },
    onFetchNextToken: (v) => v.listProfilesByOrgV2.nextToken || null,
  });
  return results
    .flatMap((result) => result.listProfilesByOrgV2.items ?? [])
    .map((item) => item.profile)
    .filter((profile) => profile !== null);
}

export async function getOrgInterviewers(
  orgId: OptionalString,
  view: InterviewerView,
  profileIdOrAlias?: string
): Promise<InterviewerProfileItem[]> {
  if (!orgId) return [];
  let interviewerProfiles: InterviewerProfileItem[];
  switch (view) {
    case InterviewerView.CALIBRATION_SHEPHERD:
      interviewerProfiles = await getOrgInterviewersByShepherd(orgId, profileIdOrAlias);
      break;
    case InterviewerView.MANAGER:
      interviewerProfiles = await getOrgInterviewersByManager(profileIdOrAlias);
      break;
    case InterviewerView.ORGANIZATION:
      interviewerProfiles = await getOrgInterviewersByOrg(orgId);
      break;
    default:
      interviewerProfiles = [];
  }
  return interviewerProfiles
    .filter((profile) => profile.status === ProfileStatus.ACTIVE)
    .map((profile) => ({
      ...profile,
      calibrations: {
        items: (profile.calibrations?.items ?? []).filter((item) => !!item.calibrationConfigID),
      },
    }));
}

async function getUserProfileClassic(alias: string): Promise<Nullable<UserProfileClassic>> {
  const document = gql(getProfileByAliasClassic);
  const result = await amplifyFetch({ document, variables: { alias } });
  return result.findProfileByAlias.items.at(0);
}

async function getUserProfilePlatform(alias: string): Promise<Nullable<UserProfilePlatform>> {
  const document = gql(getProfileByAliasPlatform);
  const result = await amplifyFetch({ document, variables: { alias } });
  return result.findProfileByAlias.items.at(0);
}

export async function getUserProfile(alias: OptionalString): Promise<UserProfile | null> {
  if (!alias) return null;
  let profile: UserProfile | null;
  if (featureFlags.PERMISSION_GROUP_MODEL.value === 'classic') {
    const result = await getUserProfileClassic(alias);
    profile = result
      ? {
          ...result,
          orgs: (result.orgs?.items ?? [])
            .map((item) => item.organization)
            .filter((org) => !!org)
            .map((org) => ({
              ...org,
              status: org.status ?? OrganizationStatus.ACTIVE,
              roleGroups: org.roleLDAPGroups?.items ?? [],
            })),
        }
      : null;
  } else {
    const result = await getUserProfilePlatform(alias);
    profile = result
      ? {
          ...result,
          orgs: (result.orgs?.items ?? [])
            .map((item) => item.organization)
            .filter((org) => !!org)
            .map((org) => ({
              ...org,
              status: org.status ?? OrganizationStatus.ACTIVE,
              roleGroups: org.roleGroups?.items ?? [],
            })),
        }
      : null;
  }
  if (!profile) return null;
  return {
    ...profile,
    orgs: (profile?.orgs ?? []).map((org) => ({
      ...org,
      roleGroups: org.roleGroups.map((rg) => ({ ...rg, group: cleanPermissionGroup(rg.group) })),
    })),
  };
}

export async function getProfileCalibrationState(
  profileId: OptionalString,
  orgId: OptionalString,
  calibrationId?: OptionalString
): Promise<CalibrationState[]> {
  if (!profileId) return [];
  const document = gql(getProfileCalibrations);
  const results = await amplifyFetch({
    document,
    variables: {
      id: profileId,
      orgID: orgId ? { eq: orgId } : null,
      calibrationsFilter: calibrationId ? { calibrationConfigID: { eq: calibrationId } } : null,
    },
    onFetchNextToken: (v) => v.getProfile.calibrations?.nextToken || null,
  });

  return results
    .flatMap((result) => result.getProfile.calibrations?.items ?? [])
    .filter((item) => !!item.calibrationConfigID)
    .map((item) => ({
      status: item.calibrationstatus,
      calibratedDate: item.calibratedDate,
      changeJustification: item.changeJustification,
      shadowCount: item.shadowCount ?? 0,
      reverseShadowCount: item.reverseShadowCount ?? 0,
      independentCount: item.independentCount ?? 0,
      shepherdReviewCount: item.reviewCount ?? 0,
      lastModifiedBy: item.lastModifiedBy,
      calibrationConfigId: item.calibrationConfigID!,
      id: item.id,
      orgId: item.orgID,
    }));
}

export async function getOrgProfilesByRole(
  orgId: OptionalString,
  includeRoles?: Nullable<Roles[]>,
  excludeRoles?: Nullable<Roles[]>
): Promise<ProfileSelectOption[]> {
  if (!orgId) return [];
  let filter: Nullable<ModelProfileOrganizationV2FilterInput>;
  let includeFilters: ModelProfileOrganizationV2FilterInput[] = [];
  let excludeFilters: ModelProfileOrganizationV2FilterInput[] = [];
  if (includeRoles?.length) {
    includeFilters = includeRoles.map((item) => ({ role: { contains: item } }));
  }
  if (excludeRoles?.length) {
    excludeFilters = excludeRoles.map((item) => ({ role: { notContains: item } }));
  }
  if (includeFilters.length) {
    filter = { or: includeFilters };
  }
  if (excludeFilters.length) {
    filter = { or: excludeFilters };
  }
  if (excludeFilters.length && includeFilters.length) {
    filter = { and: [{ or: excludeFilters }, { or: includeFilters }] };
  }
  const document = gql(getProfilesByOrgAndRole);
  const results = await amplifyFetch({
    document,
    variables: { filter, orgID: orgId },
    onFetchNextToken: (v) => v.listProfilesByOrgV2.nextToken || null,
  });
  const allOptions: ProfileSelectOption[] = results
    .flatMap((result) => result.listProfilesByOrgV2.items ?? [])
    .filter((item) => item.profile?.status === ProfileStatus.ACTIVE)
    .map((item) =>
      item.profile && item.organization
        ? {
            value: item.profile.id,
            label: item.profile.name ?? undefined,
            labelTag: item.profile.title ?? undefined,
            description: item.profile.email ?? `${item.profile.alias}@amazon.com`,
            orgID: item.orgID,
            orgName: item.organization.name,
            alias: item.profile.alias,
            email: item.profile.email ?? `${item.profile.alias}@amazon.com`,
            title: item.profile.title,
          }
        : null
    )
    .filter((item) => item !== null);

  return dedupeList(allOptions, 'value').sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''));
}

export async function getOrgInterviewerProfileOptions(
  orgId: string,
  calibrationId?: OptionalString,
  calStatusFilter?: Nullable<ModelProfileCalibrationStatusFilterInput>
): Promise<ProfileSelectOption[]> {
  if (!calibrationId) {
    return getOrgProfilesByRole(orgId);
  }
  const document = gql(getProfileCalibrationsByConfig);
  const results = await amplifyFetch({
    document,
    variables: {
      calibrationConfigID: calibrationId,
      filter: calStatusFilter || null,
    },
    onFetchNextToken: (v) => v.listCalibrationsByConfig?.nextToken || null,
  });

  return results
    .flatMap((result) => result.listCalibrationsByConfig.items ?? [])
    .filter((item) => item.profile?.status === ProfileStatus.ACTIVE)
    .map((item) =>
      item.profile && item.organization
        ? {
            value: item.profile.id,
            label: item.profile.name ?? undefined,
            labelTag: item.profile.title ?? undefined,
            description: item.profile.email ?? `${item.profile.alias}@amazon.com`,
            calibrationStatus: item.calibrationstatus,
            orgID: item.orgID,
            orgName: item.organization.name,
            alias: item.profile.alias,
            email: item.profile.email ?? `${item.profile.alias}@amazon.com`,
            title: item.profile.title,
          }
        : null
    )
    .filter((item) => item !== null);
}

export async function getOrgProfileAvailabilities(
  orgId: string,
  orgName?: string,
  weekNumber?: number,
  fallbackTimezoneId?: string
): Promise<EventAvailabilityItem[]> {
  const document = gql(getActiveProfileAvailabilitiesByOrg);
  const results = await amplifyFetch({
    document,
    variables: { orgID: orgId },
    onFetchNextToken: (v) => v.listProfileAvailabilityByOrg?.nextToken || null,
  });
  return results
    .flatMap((result) => result.listProfileAvailabilityByOrg.items ?? [])
    .filter((i): i is typeof i & { profile: NonNullable<typeof i.profile> } => i.profile?.status === ProfileStatus.ACTIVE)
    .map((item) => processEventAvailability(item, { ...item.profile, timezoneId: fallbackTimezoneId }, orgName));
}
