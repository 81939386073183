import type { Nullable } from '@amzn/elevate-data-types';
import { AwsRum } from 'aws-rum-web';
import { Context, createContext, useContext, useMemo } from 'react';

import rumConfig from '@/utilities/rum-config';

const AwsRumContext: Context<AwsRum | null> = createContext<AwsRum | null>(null);

type IAwsRumProvider = {
  children: React.ReactNode;
};

export const AwsRumProvider = ({ children }: IAwsRumProvider): React.ReactElement => {
  const client: Nullable<AwsRum> = useMemo(() => {
    try {
      return new AwsRum(...rumConfig);
    } catch {
      return null;
    }
  }, []);

  return <AwsRumContext.Provider value={client}>{children}</AwsRumContext.Provider>;
};

export function useAwsRum(): Nullable<AwsRum> {
  const client = useContext(AwsRumContext);
  return client;
}
