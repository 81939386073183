import type { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import type { SplitPanelProps } from '@amzn/awsui-components-react/polaris/split-panel';
import type { Nullable } from '@amzn/elevate-data-types';
import { store } from '@davstack/store';
import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type {
  ElevateNotification,
  IElevateSplitPanel,
  NotificationMessage,
  TypedNotificationMessage,
} from '@/common/models';
import { HELP_CONTENT, HelpContentName } from '@/resources/help-content';

type HelpState = {
  key: HelpContentName;
  visible: boolean;
};

type PanelPosition = AppLayoutProps.SplitPanelPosition;

type SplitPanelState = {
  closeBehavior: NonNullable<SplitPanelProps['closeBehavior']>;
  closed: boolean;
  content: IElevateSplitPanel['content'];
  header: IElevateSplitPanel['header'];
  hidePreferences: NonNullable<SplitPanelProps['hidePreferencesButton']>;
  position: PanelPosition;
  size: number;
  visible: boolean;
};

type Checker = () => boolean;

type ElevateAppState = {
  help: HelpState;
  isDataDirty: boolean | Checker;
  notifications: ElevateNotification[];
  splitPanel: SplitPanelState;
};

const initialState: ElevateAppState = {
  help: {
    key: 'default',
    visible: false,
  },
  isDataDirty: false,
  notifications: [],
  splitPanel: {
    closeBehavior: 'hide',
    closed: false,
    content: undefined,
    header: undefined,
    hidePreferences: true,
    position: 'bottom',
    size: 350,
    visible: false,
  },
};

const appStore = store({ ...initialState }, { name: 'elevate-app-store' })
  .actions((store) => ({
    notify: (message: TypedNotificationMessage) =>
      store.notifications.set((drafts) => {
        drafts.push(buildNotification(message));
      }),
    notifyError: (message: NotificationMessage) =>
      store.notifications.set((drafts) => {
        drafts.push(buildNotification({ ...message, type: 'error' }));
      }),
    notifySuccess: (message: NotificationMessage) =>
      store.notifications.set((drafts) => {
        drafts.push(buildNotification({ ...message, type: 'success' }));
      }),
    clearNotifications: () => store.notifications?.set([]),
    dismissNotification: (id: string) => {
      console.log(`dismissing ${id}`);
      store.notifications.set((drafts) => {
        const index = drafts.findIndex((n) => n.id === id);
        if (index !== -1) drafts.splice(index, 1);
      });
    },
    hideHelp: () => store.help.visible.set(false),
    hideSplitPanel: () => store.splitPanel.assign({ closed: true, visible: false }),
    resetHelp: () => store.help.assign({ ...initialState.help }),
    resetSplitPanel: () => store.splitPanel.assign({ ...initialState.splitPanel }),
    setHelpByName: (to: HelpContentName) =>
      store.help.assign({
        key: to,
        visible: !(store.help.visible.get() && store.help.key.get() === to),
      }),
    setSplitPanel: (to?: IElevateSplitPanel) => store.splitPanel.assign({ content: to?.content, header: to?.header }),
    showHelp: () => store.help.visible.set(true),
    showSplitPanel: () => store.splitPanel.visible.set(true),
  }))
  .computed((store) => ({
    helpContent: () => HELP_CONTENT[store.help.key.use() || 'default'],
    pendingChanges: () => {
      const isDataDirty = store.isDataDirty.use();
      return typeof isDataDirty === 'function' ? isDataDirty() : isDataDirty;
    },
  }));

export default appStore;

const TimedContent = ({ content, id }: Pick<ElevateNotification, 'id' | 'content'>): React.ReactElement => {
  const timerRef = useRef<Nullable<ReturnType<typeof setTimeout>>>(null);
  useEffect(() => {
    timerRef.current = setTimeout(() => appStore.dismissNotification(id), 15_000);
    return () => clearTimeout(timerRef.current ?? undefined);
  }, [id]);
  return <>{content}</>;
};

function buildNotification(message: TypedNotificationMessage): ElevateNotification {
  const id = uuidv4();
  return {
    ...message,
    id,
    content: <TimedContent content={message.content} id={id} />,
    dismissible: true,
    onDismiss: () => appStore.dismissNotification(id),
  };
}
