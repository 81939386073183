import { Roles } from '@amzn/elevate-graphql/types';
import { Context, createContext, useCallback, useContext, useMemo } from 'react';

import { useCognitoQuery } from '@/stores/CognitoQuery';
import { useSession } from '@/stores/SessionConfig';
import featureFlags from '@/utilities/feature-flags';
import { type AuthAction, checkConditionalAuth, checkPermissionMatrix } from '@/utilities/permissions';

type ICognitoAuthContext = {
  hasAccess: (action: AuthAction, conditionalAuthCallback?: () => boolean) => boolean;
  hasAdminAccess: boolean;
  hasElevateAccess: boolean;
  hasOrgAccess: boolean;
  hasPromoHubAccess: boolean;
  hasQuestionBankAccess: boolean;
  username: string | undefined;
  firstname: string | undefined;
  lastname: string | undefined;
  groups: string[] | undefined;
};

const emptyContext: ICognitoAuthContext = {
  hasAccess: () => false,
  hasAdminAccess: false,
  hasElevateAccess: false,
  hasOrgAccess: false,
  hasPromoHubAccess: false,
  hasQuestionBankAccess: false,
  username: undefined,
  firstname: undefined,
  lastname: undefined,
  groups: undefined,
};

const CognitoAuthContext: Context<ICognitoAuthContext> = createContext(emptyContext);

export function CognitoAuthProvider({ children }): JSX.Element {
  const { data: cognitoQueryData } = useCognitoQuery();
  const { sessionObject } = useSession();

  const hasElevateAccess = useMemo(() => !!cognitoQueryData?.validElevateUser, [cognitoQueryData?.validElevateUser]);
  const hasPromoHubAccess = useMemo(() => hasElevateAccess && featureFlags.PROMOHUB_ENABLED.value, [hasElevateAccess]);

  const hasOrgAccess = useMemo(() => {
    const groups = (cognitoQueryData?.groups ?? []).filter((g) => !g.endsWith('_profile'));

    return hasElevateAccess && !!groups.length;
  }, [hasElevateAccess, cognitoQueryData?.groups]);

  const hasAdminAccess = useMemo(
    () => hasElevateAccess && !!cognitoQueryData?.isElevateAdmin && cognitoQueryData.groups.includes(Roles.ELEVATE_ADMIN),
    [hasElevateAccess, cognitoQueryData?.groups, cognitoQueryData?.isElevateAdmin]
  );

  const hasQuestionBankAccess = useMemo(
    () => hasElevateAccess && featureFlags.QUESTION_BANK_ENABLED.value,
    [hasElevateAccess]
  );

  const hasAccess = useCallback(
    (action: AuthAction, conditionalAuthCallback?: () => boolean) => {
      if (!(cognitoQueryData && sessionObject.activeOrgID && hasElevateAccess)) return false;
      if (hasAdminAccess) return true;
      if (!sessionObject.orgPermissions) return false;
      const activeOrgGroups = sessionObject.orgPermissions.get(sessionObject.activeOrgID);

      const hasUnconditionalAuth = cognitoQueryData.groups.some((group) =>
        [...(activeOrgGroups?.get(group) ?? [])].some((group) => checkPermissionMatrix(action, group))
      );

      return hasUnconditionalAuth || checkConditionalAuth(action, conditionalAuthCallback);
    },
    [cognitoQueryData, hasAdminAccess, hasElevateAccess, sessionObject.activeOrgID, sessionObject.orgPermissions]
  );

  const context = {
    hasAccess,
    hasAdminAccess,
    hasElevateAccess,
    hasOrgAccess,
    hasPromoHubAccess,
    hasQuestionBankAccess,
    username: cognitoQueryData?.username,
    firstname: cognitoQueryData?.firstName,
    lastname: cognitoQueryData?.lastName,
    groups: cognitoQueryData?.groups,
  } satisfies ICognitoAuthContext;

  return <CognitoAuthContext.Provider value={context}>{children}</CognitoAuthContext.Provider>;
}

export function useCognitoAuth() {
  const context = useContext(CognitoAuthContext);

  if (context === emptyContext) {
    throw new Error('useCognitoAuth must be used within its provider');
  }

  return context;
}
