import { ElevateApps } from '@amzn/elevate-graphql/types';
import { useQueryClient } from '@tanstack/react-query';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';

import RouteGuard from '@/common/RouteGuard';
import FullPageSpinner from '@/components/common/FullPageSpinner';
import { routes as questionBankRoutes } from '@/components/QuestionBank/routes';
import { useCognitoAuth } from '@/stores/CognitoAuth';
import { auth_actions, STATIC_ROUTES } from '@/utilities/constants';
import featureFlags from '@/utilities/feature-flags';
import { ElevateAppRoutes } from '@/utilities/page-definitions';

const isPromoHubEnabled = featureFlags.PROMOHUB_ENABLED.value;

export default function ElevateAppRouter(): React.ReactElement {
  const { hasAccess, hasOrgAccess, hasPromoHubAccess } = useCognitoAuth();
  const queryClient = useQueryClient();

  const router = createBrowserRouter([
    {
      hydrateFallbackElement: <FullPageSpinner />,
      async lazy() {
        const { default: App } = await import('@/App');
        const { ElevateRouteError } = await import('@/components/app/ElevateRouteError');

        return {
          element: <App />,
          errorElement: <ElevateRouteError />,
        };
      },
      children: [
        {
          path: '*',
          element: <Navigate to={STATIC_ROUTES.NOT_FOUND} relative="route" replace />,
        },
        {
          path: STATIC_ROUTES.ACCESS_DENIED,
          async lazy() {
            const { default: AccessDenied } = await import('@/components/app/AccessDenied');
            return {
              element: <AccessDenied />,
            };
          },
        },
        {
          path: STATIC_ROUTES.NOT_FOUND,
          async lazy() {
            const { default: NotFound } = await import('@/components/app/NotFound');
            return {
              element: <NotFound />,
            };
          },
        },
        {
          path: ElevateAppRoutes[ElevateApps.NONE],
          async lazy() {
            const { default: MainLandingPage } = await import('@/components/LandingPages/MainLandingPage');
            return {
              element: <MainLandingPage />,
            };
          },
        },
        {
          path: ElevateAppRoutes[ElevateApps.INTERVIEW_MANAGEMENT],
          async lazy() {
            const { default: InterviewManagementLandingPage } = await import(
              '@/components/LandingPages/InterviewManagementLandingPage'
            );
            return {
              element: <InterviewManagementLandingPage />,
            };
          },
        },
        {
          path: 'profile/:tab?',
          async lazy() {
            const { Profile } = await import('@/components/Profile/Profile');
            return {
              element: <Profile key="profile-tabs" />,
            };
          },
        },
        {
          path: '/:orgId',
          async lazy() {
            const { default: OrgRouteWrapper } = await import('@/components/Organization/OrgRouteWrapper');
            return {
              element: (
                <RouteGuard isAllowed={hasOrgAccess}>
                  <OrgRouteWrapper />
                </RouteGuard>
              ),
            };
          },
          children: [
            {
              path: 'phone-screens',
              element: <Outlet />,
              children: [
                {
                  index: true,
                  async lazy() {
                    const { default: PhoneScreenList } = await import('@/components/PhoneScreen/PhoneScreenList');
                    return {
                      element: <PhoneScreenList key="ivm-ps-view" activeOrgOnly simpleView />,
                    };
                  },
                },
                {
                  path: 'find',
                  async lazy() {
                    const { default: PhoneScreenList } = await import('@/components/PhoneScreen/PhoneScreenList');
                    return {
                      element: <PhoneScreenList key="ivm-ps-find" showCreateEditDelete={false} />,
                    };
                  },
                },
                {
                  path: ':eventId',
                  async lazy() {
                    const { default: PhoneScreenView } = await import('@/components/PhoneScreen/PhoneScreenView');
                    return {
                      element: <PhoneScreenView />,
                    };
                  },
                },
                {
                  path: 'find/shadow',
                  async lazy() {
                    const { default: FindPhoneScreenOpportunity } = await import(
                      '@/components/FindEventOpportunity/FindPhoneScreenOpportunity'
                    );
                    return {
                      element: (
                        <RouteGuard isAllowed={hasAccess(auth_actions.VIEW_PHONE_SCREEN_SHADOW_OPPORTUNITIES)}>
                          <FindPhoneScreenOpportunity key="ivm-ps-shadow" isShadowMode />
                        </RouteGuard>
                      ),
                    };
                  },
                },
                {
                  path: 'available-interviewers',
                  async lazy() {
                    const { default: FindAvailableInterviewer } = await import(
                      '@/components/FindAvailableInterviewer/FindAvailableInterviewer'
                    );
                    return {
                      element: (
                        <RouteGuard isAllowed={hasAccess(auth_actions.FIND_AVAILABLE_INTERVIEWER)}>
                          <FindAvailableInterviewer key="ivm-ps-availinterviewers" />
                        </RouteGuard>
                      ),
                    };
                  },
                },
                {
                  element: <RouteGuard isAllowed={hasAccess(auth_actions.CREATE_PHONE_SCREEN)} />,
                  children: [
                    {
                      path: 'create',
                      async lazy() {
                        const { default: PhoneScreenForm } = await import('@/components/PhoneScreen/PhoneScreenForm');
                        return {
                          element: <PhoneScreenForm key="ivm-ps-create" />,
                        };
                      },
                    },
                    {
                      path: ':eventId/edit',
                      async lazy() {
                        const { default: PhoneScreenForm } = await import('@/components/PhoneScreen/PhoneScreenForm');
                        return {
                          element: <PhoneScreenForm key="ivm-ps-edit" />,
                        };
                      },
                    },
                    {
                      path: 'create/fromAvailability/:availabilityID',
                      async lazy() {
                        const { default: PhoneScreenForm } = await import('@/components/PhoneScreen/PhoneScreenForm');
                        return {
                          element: <PhoneScreenForm key="ivm-ps-cfa" />,
                        };
                      },
                    },
                    {
                      path: 'create/fromAvailability/:availabilityID/:availabilityDate',
                      async lazy() {
                        const { default: PhoneScreenForm } = await import('@/components/PhoneScreen/PhoneScreenForm');
                        return {
                          element: <PhoneScreenForm key="ivm-ps-cfad" />,
                        };
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: 'loops',
              element: <Outlet />,
              children: [
                {
                  index: true,
                  async lazy() {
                    const { default: LoopEventList } = await import('@/components/LoopEvent/LoopEventList');
                    return {
                      element: <LoopEventList key="ivm-loops-view" activeOrgOnly simpleView />,
                    };
                  },
                },
                {
                  path: ':eventId',
                  async lazy() {
                    const { default: LoopEventView } = await import('@/components/LoopEvent/LoopEventView');
                    return {
                      element: <LoopEventView />,
                    };
                  },
                },
                {
                  element: <RouteGuard isAllowed={hasAccess(auth_actions.CREATE_LOOP_EVENT)} />,
                  children: [
                    {
                      path: 'bulk-create',
                      async lazy() {
                        const { default: BulkLoopCreate } = await import('@/components/LoopEvent/BulkLoopCreate');
                        return {
                          element: <BulkLoopCreate key="ivm-loop-create-bulk" />,
                        };
                      },
                    },
                    {
                      path: 'create',
                      async lazy() {
                        const { default: LoopEventForm } = await import('@/components/LoopEvent/LoopEventForm');
                        return {
                          element: <LoopEventForm key="ivm-loop-create" />,
                        };
                      },
                    },
                    {
                      path: ':eventId/edit',
                      async lazy() {
                        const { default: LoopEventForm } = await import('@/components/LoopEvent/LoopEventForm');
                        return {
                          element: <LoopEventForm key="ivm-loop-edit" />,
                        };
                      },
                    },
                  ],
                },
                {
                  path: 'find/lead',
                  async lazy() {
                    const { default: FindLoopOpportunity } = await import(
                      '@/components/FindEventOpportunity/FindLoopOpportunity'
                    );
                    return {
                      element: <FindLoopOpportunity key="ivm-loop-find-lead" />,
                    };
                  },
                },
                {
                  path: 'find/shadow',
                  async lazy() {
                    const { default: FindLoopOpportunity } = await import(
                      '@/components/FindEventOpportunity/FindLoopOpportunity'
                    );
                    return {
                      element: <FindLoopOpportunity key="ivm-loop-find-shadow" isShadowMode />,
                    };
                  },
                },
              ],
            },
            {
              path: 'team',
              element: <RouteGuard isAllowed={true} />,
              children: [
                {
                  path: 'calibration-status',
                  async lazy() {
                    const { ManageTeamMembers } = await import('@/components/ManageInterviewers');
                    return {
                      element: <ManageTeamMembers />,
                    };
                  },
                },
              ],
            },
            {
              path: 'bar-raisers',
              async lazy() {
                const { default: BarRaiserCards } = await import('@/components/BarRaiser/BarRaiserCards');
                return {
                  element: (
                    <RouteGuard isAllowed={hasAccess(auth_actions.VIEW_BAR_RAISERS)}>
                      <BarRaiserCards key="ivm-br-list" />
                    </RouteGuard>
                  ),
                };
              },
            },
            {
              path: 'calibration-shepherds',
              async lazy() {
                const { default: CalibrationShepherdAssignment } = await import(
                  '@/components/CalibrationShepherdAssignment/CalibrationShepherdAssignment'
                );
                return {
                  element: (
                    <RouteGuard isAllowed={hasAccess(auth_actions.INTERVIEWER_MANAGEMENT)}>
                      <CalibrationShepherdAssignment key="ivm-cs-lookup" />
                    </RouteGuard>
                  ),
                };
              },
            },
            {
              path: 'calibration-shepherd',
              element: <RouteGuard isAllowed={hasAccess(auth_actions.INTERVIEWER_MANAGEMENT)} />,
              children: [
                {
                  path: 'interviewers',
                  async lazy() {
                    const { ManageFlockMembers } = await import('@/components/ManageInterviewers');
                    return {
                      element: <ManageFlockMembers />,
                    };
                  },
                },
                {
                  path: 'interviewers/:impersonateProfileId/:tab?',
                  async lazy() {
                    const { ProfileWithImpersonation } = await import('./components/Profile/Profile');
                    return {
                      element: <ProfileWithImpersonation key="ivm-cs-impersonate" isCalShepherdView />,
                    };
                  },
                },
              ],
            },
            {
              path: 'interviewers',
              async lazy() {
                const { ListOrgInterviewers } = await import('@/components/ManageInterviewers');
                return {
                  element: (
                    <RouteGuard isAllowed={hasAccess(auth_actions.LOOKUP_INTERVIEWERS)}>
                      <ListOrgInterviewers />
                    </RouteGuard>
                  ),
                };
              },
            },
          ],
        },
        {
          path: ElevateAppRoutes[ElevateApps.PROMOHUB],
          async lazy() {
            const { default: PromoHubLandingPage } = await import('@/components/LandingPages/PromoHubLandingPage');
            return {
              element: isPromoHubEnabled ? (
                <RouteGuard isAllowed={hasPromoHubAccess}>
                  <PromoHubLandingPage />
                </RouteGuard>
              ) : (
                <Navigate to={STATIC_ROUTES.NOT_FOUND} relative="route" />
              ),
            };
          },
        },
        {
          path: 'organizations',
          element: <RouteGuard isAllowed={hasAccess(auth_actions.VIEW_ORGANIZATIONS)} />,
          children: [
            {
              index: true,
              async lazy() {
                const { default: ListOrganizations } = await import('@/components/Organization/ListOrganizations');
                return {
                  element: <ListOrganizations key="orgs-list" />,
                };
              },
            },
            {
              path: ':orgId',
              async lazy() {
                const { default: OrgRouteWrapper } = await import('@/components/Organization/OrgRouteWrapper');
                return {
                  element: (
                    <RouteGuard isAllowed={hasOrgAccess}>
                      <OrgRouteWrapper />
                    </RouteGuard>
                  ),
                };
              },
              children: [
                {
                  path: 'settings/:tab?',
                  async lazy() {
                    const { default: OrganizationDetail } = await import('@/components/Organization/OrganizationDetail');
                    return {
                      element: <OrganizationDetail key="ivm-org-settings" />,
                    };
                  },
                },
              ],
            },
          ],
        },
        ...questionBankRoutes(queryClient),
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
