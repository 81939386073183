import type { DatePickerProps } from '@amzn/awsui-components-react';
import type { Nullable } from '@amzn/elevate-data-types';
import capitalize from 'lodash/capitalize';

export const externalLinkProps = {
  external: true,
  externalIconAriaLabel: 'Opens in a new tab',
};

export const calendarAriaLabel: DatePickerProps.OpenCalendarAriaLabel = (value) =>
  value ? `Choose date, selected date is ${value}` : 'Choose date';

export function getLabelFromEnum(value: string): string {
  return capitalize(value).replaceAll(/[_-]/g, ' ');
}

export function getReadableFromEnum(value: string): string {
  return value.replaceAll(/[_-]/g, ' ').toLowerCase();
}

export function pluralize(value: string): string {
  let valuePlural = `${value}es`;
  if (value.endsWith('y')) {
    valuePlural = `${value.slice(0, -1)}ies`;
  } else if (
    value.endsWith('n') ||
    value.endsWith('p') ||
    value.endsWith('r') ||
    value.endsWith('d') ||
    value.endsWith('e') ||
    value.endsWith('ck') ||
    value.endsWith('t') ||
    value.endsWith('th') ||
    value.endsWith('l') ||
    value.endsWith('w') ||
    value.endsWith('m')
  ) {
    valuePlural = `${value}s`;
  }
  return valuePlural;
}

export function getHeaderCounterText<T = unknown>(allItems: T[], selectedItems: Nullable<T[]>): string {
  return selectedItems?.length ? `(${selectedItems.length}/${allItems.length})` : `(${allItems.length})`;
}

export function getServerHeaderCounterText<T = unknown>(allItems: T[], selectedItems: Nullable<T[]>): string {
  return selectedItems?.length ? `(${selectedItems.length}/${allItems.length}+)` : `(${allItems.length}+)`;
}
