import type { FixedResult, OptionalNumber, OptionalString } from '@amzn/elevate-data-types';
import type { ResultOf } from '@graphql-typed-document-node/core';
import { generateClient } from 'aws-amplify/api';
import { type OperationTypeNode, parse, print } from 'graphql';
import type { OperationDefinitionNode } from 'graphql/language/ast';

import type { GeneratedMutation, GeneratedQuery, TypedDocumentString } from '@/graphql/types';
import { APPSYNC_GRAPHQL_GET_LIMIT } from '@/utilities/constants';

export type APIReturnType<T> = FixedResult<ResultOf<T>>;

/** Amplify GraphQL client */
const client = generateClient();

/**
 * This types a GraphQL string according to its operation type, allowing the Amplify `graphql` method to infer the
 * correct return type. Intentionally not allowing subscriptions as their return type is very different, so should
 * be handled separately.
 */
type TypedOperationString<OP extends OperationTypeNode, TVariables, TResult> = OP extends 'query'
  ? GeneratedQuery<TVariables, TResult>
  : OP extends 'mutation'
    ? GeneratedMutation<TVariables, TResult>
    : never;

/** Query variables can have any assortment of keys and values */
export type GQLVariables = Record<string, unknown>;
export type GQLResult = Record<string, unknown>;

/** Helper type to extend the query input with 2 known variables on all queries that return > 1 results. */
type ListQueryVariables<TVariables extends GQLVariables, K extends string> = TVariables & {
  [key in K]: OptionalString;
} & { limit?: OptionalNumber };

/**
 * Check if this gql query is meant to return 1+ items (list), and if so, extend TVariables with `nextToken` and `limit`
 * All queries that can return 1+ items will accept these 2 input variables. The only way for us to know whether the
 * query accepts them is to inspect the operation definition for these variables.
 */
function isListQuery<TVariables extends GQLVariables, K extends string>(
  v: TVariables,
  k: K,
  definition: OperationDefinitionNode
): v is ListQueryVariables<TVariables, K> {
  return !!v && typeof v === 'object' && variablesCanInclude(definition, 'limit') && variablesCanInclude(definition, k);
}

function isNextToken(token: unknown): token is OptionalString {
  return typeof token === 'string' || token === null || token === undefined;
}
/**
 * Checks whether a given gql query/mutation/subscription supports the given input variable name.
 * This is a blind check in that we don't validate anythign about the `key` value, aside from that it exists
 * in the variable set.
 */
function variablesCanInclude(definition: OperationDefinitionNode, key: string) {
  return (definition.variableDefinitions ?? []).some((varDef) => varDef.variable.name.value === key);
}

type IFetchBase<TResult extends GQLResult, TVariables extends GQLVariables> = {
  document: TypedDocumentString<TResult, TVariables>;
  signal?: AbortSignal;
  variables: TVariables;
};

type FetchOne<TResult extends GQLResult, TVariables extends GQLVariables> = IFetchBase<TResult, TVariables> & {
  nextTokenKey?: null;
  onFetchNextToken?: null;
};

type FetchPaged<TResult extends GQLResult, TVariables extends GQLVariables> = IFetchBase<TResult, TVariables> & {
  nextTokenKey?: keyof Required<TVariables> & string;
  onFetchNextToken: (v: FixedResult<TResult>) => OptionalString;
};

type IFetch<TResult extends GQLResult, TVariables extends GQLVariables> =
  | FetchOne<TResult, TVariables>
  | FetchPaged<TResult, TVariables>;

export async function amplifyFetch<TResult extends GQLResult, TVariables extends GQLVariables>(
  params: FetchOne<TResult, TVariables>
): Promise<FixedResult<TResult>>;

export async function amplifyFetch<TResult extends GQLResult, TVariables extends GQLVariables>(
  params: FetchPaged<TResult, TVariables>
): Promise<FixedResult<TResult>[]>;

export async function amplifyFetch<TResult extends GQLResult, TVariables extends GQLVariables>(
  params: IFetch<TResult, TVariables>
) {
  const { document, variables, onFetchNextToken, nextTokenKey, signal } = params;
  const definition = parse(document.toString()).definitions[0] as OperationDefinitionNode;
  if (!definition) throw new Error('No graphql query definition found');
  let _variables = { ...variables };
  const _nextTokenKey: string = nextTokenKey ?? 'nextToken';
  let nextToken: OptionalString = null;
  if (isListQuery(_variables, _nextTokenKey, definition)) {
    if (!_variables.limit) _variables.limit = APPSYNC_GRAPHQL_GET_LIMIT;
    nextToken = isNextToken(_variables[_nextTokenKey]) ? _variables[_nextTokenKey] : null;
  }
  const query = print(definition) as TypedOperationString<typeof definition.operation, TVariables, TResult>;
  const results: FixedResult<TResult>[] = [];
  let apiRequest: ReturnType<typeof client.graphql<TResult, typeof query>> | null = null;
  signal?.addEventListener('abort', () => {
    if (!apiRequest) return;
    client.cancel(apiRequest, 'Query cancelled by Tanstack');
  });

  do {
    if (nextToken) _variables = { ..._variables, [_nextTokenKey]: nextToken };
    apiRequest = client.graphql({ query, variables: _variables });
    const result = await apiRequest;
    const data = result.data as FixedResult<TResult>;
    results.push(data);
    nextToken = typeof onFetchNextToken === 'function' ? onFetchNextToken(data) : undefined;
  } while (nextToken);

  return typeof onFetchNextToken === 'function' ? results : results[0];
}

export const gqlClient = client;
