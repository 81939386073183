import type { Nullable, OptionalNumber, OptionalString } from '@amzn/elevate-data-types';
import {
  DateTime,
  Duration,
  type DurationLikeObject,
  type DurationUnit,
  type ToHumanDurationOptions,
  type WeekdayNumbers,
} from 'luxon';

import { getTimezoneAbbreviation } from './timezones';

type FixedLengthString<N extends number> = string & { length: N };

type DateString = FixedLengthString<10>;
export type TimeString = FixedLengthString<5>;
type DateTimeString = string;
type DateTimeParts = { date: OptionalString; time: OptionalString };

const TIME_FMT = 'HH:mm';
const ISO_DATE_REGEX = new RegExp(/^((?:19|20)\d\d)[ ./-](0[1-9]|1[0-2])[ ./-](0[1-9]|[12]\d|3[01])$/);
const ISO_TIME_REGEX = new RegExp(/^(2[0-3]|[01]?\d):([0-5]?\d)(:([0-5]?\d)|)$/);

/**
 * Checks whether the specified value matches the length and format of
 * date values the app is expecting to work with.
 *
 * Requirements:
 *  - string
 *  - 10 characters in length
 *  - Matches the ISO date format `yyyy-MM-dd`
 */
export const isValidDate = (value: OptionalString): value is DateString =>
  typeof value === 'string' && value.length === 10 && ISO_DATE_REGEX.test(value);

/**
 * Checks whether the specified value matches the length and format of
 * time values the app is expecting to work with.
 *
 * Requirements:
 *  - string
 *  - 5 characters in length
 *  - Matches the time format `HH:mm`
 */
export const isValidTime = (value: OptionalString): value is TimeString =>
  typeof value === 'string' && value.length === 5 && ISO_TIME_REGEX.test(value);

export function isValidDateTime(value: OptionalString): value is DateTimeString {
  return !!value && isValidDate(value.split('T')[0]) && isValidTime(value.split('T')[1].split('.')[0]);
}

function isOptionalString(value: OptionalString | DateTimeParts): value is OptionalString {
  return !value || typeof value === 'string';
}

type FromDateTimeParams = [Nullable<DateTime>, OptionalString?];
type FromStringParams = [DateString, TimeString, OptionalString];

export class ElevateDateTime {
  private readonly dateTimeInner: Nullable<DateTime>;

  private constructor();

  private constructor(dateTime: Nullable<DateTime>, reqTimezoneId?: OptionalString);

  private constructor(date: DateString, time: TimeString, reqTimezoneId: OptionalString);

  private constructor(...arr: FromDateTimeParams | FromStringParams) {
    if (arr[0] instanceof DateTime) {
      this.dateTimeInner = typeof arr[1] === 'string' ? arr[0].setZone(arr[1]) : arr[0];
    } else if (arr.length === 3 && isValidDate(arr[0]) && isValidTime(arr[1])) {
      this.dateTimeInner = DateTime.fromISO(`${arr[0]}T${arr[1]}`, { zone: arr[2] || 'utc' });
    }
  }

  private isDateTime = (value: Nullable<DateTime>): value is DateTime => !!value && value.isValid;

  /**
   * @returns The inner Luxon `DateTime` instance, or undefined
   */
  toLuxon = (): Nullable<DateTime> => this.dateTimeInner;

  /**
   * Returns a primitive representation of the date-time
   * for JS to use in object < > compare checks
   */
  valueOf() {
    return this.dateTimeInner?.toMillis();
  }

  equals(compare: ElevateDateTime) {
    return this.isValid && compare.isValid && this.epochMillis === compare.epochMillis;
  }

  /**
   * Whether this `ElevateDateTime` instance is valid.
   *
   * Criteria:
   *  - inner `DateTime` value is not null or undefined
   *  - Luxon's `isValid` property on the inner `DateTime` instance is `true`
   */
  get isValid(): boolean {
    return this.isDateTime(this.dateTimeInner);
  }

  /**
   * Checks whether this date-time is before the provided `compare` date-time.
   * Both values are assumed to be in the same timezone.
   * @param compare An `ElevateDateTime` instance to compare this date-time against.
   * @returns Whether this `ElevateDateTime` is before the passed in value.
   */
  isBefore(compare: ElevateDateTime): boolean {
    const compareDateTime = compare.toLuxon();
    return this.isDateTime(this.dateTimeInner) && this.isDateTime(compareDateTime) && this.dateTimeInner < compareDateTime;
  }

  /**
   * Checks whether this day is before the provided `compare` day.
   * Both values are assumed to be in the same timezone.
   * @param compare An `ElevateDateTime` instance to compare this day against.
   * @returns Whether this `ElevateDateTime` is before the passed in value.
   */
  isBeforeDay(compare: ElevateDateTime): boolean {
    const compareDateTime = compare.toLuxon();
    return (
      this.isDateTime(this.dateTimeInner) &&
      this.isDateTime(compareDateTime) &&
      this.dateTimeInner.startOf('day') < compareDateTime.startOf('day')
    );
  }

  /**
   * Checks whether this date-time is after the provided `compare` date-time.
   * Both values are assumed to be in the same timezone.
   * @param compare An `ElevateDateTime` instance to compare this date-time against.
   * @returns Whether this `ElevateDateTime` is after the passed in value.
   */
  isAfter(compare: ElevateDateTime): boolean {
    const compareDateTime = compare.toLuxon();
    return this.isDateTime(this.dateTimeInner) && this.isDateTime(compareDateTime) && this.dateTimeInner > compareDateTime;
  }

  /**
   * Checks whether this day is after the provided `compare` day.
   * Both values are assumed to be in the same timezone.
   * @param compare An `ElevateDateTime` instance to compare this day against.
   * @returns Whether this `ElevateDateTime` is after the passed in value.
   */
  isAfterDay(compare: ElevateDateTime): boolean {
    const compareDateTime = compare.toLuxon();
    return (
      this.isDateTime(this.dateTimeInner) &&
      this.isDateTime(compareDateTime) &&
      this.dateTimeInner.endOf('day') > compareDateTime.endOf('day')
    );
  }

  /**
   * Adds the specified number of minutes to this date-time.
   * If the date-time is valid, a new `ElevateDateTime` instance is returned with the added minutes,
   * otherwise a new, empty, `ElevateDateTime` instance is returned.
   * @param minutes Number of minutes to add (can be 0)
   * @returns A new `ElevateDateTime` instance.
   */
  addMinutes(minutes: number): ElevateDateTime {
    if (this.isDateTime(this.dateTimeInner)) {
      return new ElevateDateTime(this.dateTimeInner?.plus({ minutes }), this.dateTimeInner?.zoneName);
    }
    return new ElevateDateTime();
  }

  /**
   * Adds the specified number of days to this date-time.
   * If the date-time is valid, a new `ElevateDateTime` instance is returned with the added days,
   * otherwise a new, empty, `ElevateDateTime` instance is returned.
   * @param days Number of days to add (can be 0)
   * @returns A new `ElevateDateTime` instance.
   */
  addDays(days: number): ElevateDateTime {
    if (this.isDateTime(this.dateTimeInner)) {
      return new ElevateDateTime(this.dateTimeInner?.plus({ days }), this.dateTimeInner?.zoneName);
    }
    return new ElevateDateTime();
  }

  /**
   * Adds the specified number of weeks to this date-time.
   * If the date-time is valid, a new `ElevateDateTime` instance is returned with the added weeks,
   * otherwise a new, empty, `ElevateDateTime` instance is returned.
   * @param weeks Number of weeks to add (can be 0)
   * @returns A new `ElevateDateTime` instance.
   */
  addWeeks(weeks: number): ElevateDateTime {
    if (this.isDateTime(this.dateTimeInner)) {
      return new ElevateDateTime(this.dateTimeInner.plus({ weeks }), this.dateTimeInner?.zoneName);
    }
    return new ElevateDateTime();
  }

  /**
   * Adds the specified number of years to this date-time.
   * If the date-time is valid, a new `ElevateDateTime` instance is returned with the added years,
   * otherwise a new, empty, `ElevateDateTime` instance is returned.
   * @param years Number of years to add (can be 0)
   * @returns A new `ElevateDateTime` instance.
   */
  addYears(years: number): ElevateDateTime {
    if (this.isDateTime(this.dateTimeInner)) {
      return new ElevateDateTime(this.dateTimeInner.plus({ years }), this.dateTimeInner?.zoneName);
    }
    return new ElevateDateTime();
  }

  /**
   * Sets the weekday of the date-time to the requested day.
   * If the date-time is valid, a new `ElevateDateTime` instance is returned, set to the new week day.
   * otherwise a new, empty, `ElevateDateTime` instance is returned.
   * @param weekday Numeric day of the week to set the date-time to.
   * @returns new `ElevateDateTime` instance.
   */
  changeDayOfWeek(weekday: WeekdayNumbers): ElevateDateTime {
    if (this.isDateTime(this.dateTimeInner)) {
      return new ElevateDateTime(this.dateTimeInner.set({ weekday }), this.dateTimeInner?.zoneName);
    }
    return new ElevateDateTime();
  }

  /**
   * Sets the `date` of this date-time to the requested value, returning the new `ElevateDateTime`.
   * If the date-time isn't valid, an empty `ElevateDateTime` instance is returned.
   * @param value The date to set, as either a string in `yyyy-MM-dd` format, or a `ElevateDateTime` instance.
   * @returns new `ElevateDateTime` instance.
   */
  changeDate(value: DateString | ElevateDateTime): ElevateDateTime {
    const date = typeof value === 'string' && isValidDate(value) ? value : value.date;
    if (this.isDateTime(this.dateTimeInner) && isValidDate(date) && isValidTime(this.time)) {
      return new ElevateDateTime(date, this.time, this.dateTimeInner.zoneName);
    }
    return new ElevateDateTime();
  }

  /**
   * Sets the `time` of this date-time to the requested value, returning the new `ElevateDateTime`.
   * If the date-time isn't valid, an empty `ElevateDateTime` instance is returned.
   * @param value The requested time to set, formatted as `HH:mm`
   * @returns new `ElevateDateTime` instance.
   */
  changeTime(value: Nullable<TimeString>): ElevateDateTime {
    if (this.isDateTime(this.dateTimeInner) && isValidDate(this.date) && isValidTime(value)) {
      return new ElevateDateTime(this.date, value, this.dateTimeInner.zoneName);
    }
    return new ElevateDateTime();
  }

  /**
   * Sets the current date-time to the start of the day, returning the new `ElevateDateTime` instance.
   * If the date-time is invalid, an empty `ElevateDateTime` instance is returned.
   * @example 2023-05-02 09:30 MST => 2023-05-02 00:00 MST
   */
  get startOfDay(): ElevateDateTime {
    if (this.isDateTime(this.dateTimeInner)) {
      return new ElevateDateTime(this.dateTimeInner.startOf('day'));
    }
    return new ElevateDateTime();
  }

  /** The epoch milliseconds value of the date-time. If the date-time isn't valid, returns 0 */
  get epochMillis() {
    return this.isDateTime(this.dateTimeInner) ? this.dateTimeInner.toMillis() : 0;
  }

  /**
   * Compares an `ElevateDateTime` instance with this one, and determines how many minutes
   * have passed. The `ElevateDateTime` instance passed in is expected to be before this one.
   * @param compare The earlier `ElevateDateTime` instance to compare against.
   * @returns Number of minutes elapsed since the passed in `ElevateDateTime` and this one.
   */
  getMinutesSince(compare: ElevateDateTime) {
    const compareDateTime = compare.toLuxon();
    return this.isDateTime(this.dateTimeInner) && this.isDateTime(compareDateTime)
      ? this.dateTimeInner.diff(compareDateTime, ['minutes']).minutes
      : 0;
  }

  /**
   * The timezone local date of the date-time.
   * The timezone is based on the zone specified when creating the `ElevateDateTime`.
   * @returns type-safe `date` component of the date-time, or undefined.
   */
  get date(): Nullable<DateString> {
    const date = this.dateTimeInner?.toISODate();
    return isValidDate(date) ? date : undefined;
  }

  /**
   * The UTC normalized date of the date-time; suitable for storing to the database.
   * If the date-time is already in the UTC timezone, this will match the `date` property.
   * @returns type-safe `date` component of the date-time, rounded to UTC, or undefined.
   */
  get dateUTC(): Nullable<DateString> {
    const date = this.dateTimeInner?.toUTC()?.toISODate();
    return isValidDate(date) ? date : undefined;
  }

  /**
   * The timezone local time of the date-time.
   * The timezone is based on the zone specified when creating the `ElevateDateTime`.
   * @returns type-safe `time` component of the date-time, or undefined.
   */
  get time(): Nullable<TimeString> {
    const time = this.dateTimeInner?.toFormat(TIME_FMT);
    return isValidTime(time) ? time : undefined;
  }

  /**
   * The UTC normalized time of the date-time; suitable for storing to the database.
   * If the date-time is already in the UTC timezone, this will match the `time` property.
   * @returns type-safe `time` component of the date-time, rounded to UTC, or undefined.
   */
  get timeUTC(): Nullable<TimeString> {
    const time = this.dateTimeInner?.toUTC()?.toFormat(TIME_FMT);
    return isValidTime(time) ? time : undefined;
  }

  /**
   * The timezone local date-time value.
   * The timezone is based on the zone specified when creating the `ElevateDateTime`.
   * @returns type-safe date-time value, or undefined.
   */
  get dateTime() {
    return this.isDateTime(this.dateTimeInner)
      ? this.dateTimeInner.toISO({ includeOffset: false, suppressMilliseconds: true, suppressSeconds: true })
      : undefined;
  }

  /**
   * The UTC normalized date-time value; suitable for storing to the database.
   * If the date-time is already in the UTC timezone, this will match the `dateTime` property.
   * @returns type-safe date-time value, rounded to UTC, or undefined.
   */
  get dateTimeUTC() {
    return this.isDateTime(this.dateTimeInner)
      ? this.dateTimeInner.toUTC().toISO({ includeOffset: false, suppressMilliseconds: true, suppressSeconds: true })
      : undefined;
  }

  /**
   * Get time offset from UTC
   */
  get utcOffset() {
    return this.isDateTime(this.dateTimeInner) ? this.dateTimeInner.offset / 60 : undefined;
  }

  /**
   * A null-save, human readable, version of the date value (no time).
   * This should only be used in UI components for display, and not stored to the database.
   * @returns The ISO date as `yyyy-MM-dd` or an empty string.
   * @example 2023-05-02
   */
  get displayDate(): string {
    return this.date ?? '';
  }

  /**
   * A null-save, human readable, version of the time value (no date).
   * This should only be used in UI components for display, and not stored to the database.
   * @returns The time as `HH:mm` + timezone abbreviation, or an empty string.
   * @example 09:30 MST
   */
  get displayTime(): string {
    if (this.isDateTime(this.dateTimeInner) && this.time) {
      const abbreviation = getTimezoneAbbreviation(this.dateTimeInner.toUnixInteger() * 1000, this.dateTimeInner.zone);
      return `${this.time} ${abbreviation}`;
    }
    return '';
  }

  /**
   * A null-save, human readable, version of the full date-time value.
   * This should only be used in UI components for display, and not stored to the database.
   * @returns The ISO date-time as `yyyy-MM-dd HH:mm` + timezone abbreviation, or an empty string.
   * @example 2023-05-02 09:30 MST
   */
  get displayDateTime(): string {
    if (this.isDateTime(this.dateTimeInner) && this.date && this.time) {
      const abbreviation = getTimezoneAbbreviation(this.dateTimeInner.toUnixInteger() * 1000, this.dateTimeInner.zone);
      return `${this.date} ${this.time} ${abbreviation}`;
    }
    return '';
  }

  static now(timezoneId?: OptionalString): ElevateDateTime {
    return new ElevateDateTime(DateTime.now(), timezoneId);
  }

  /**
   * Creates an `ElevateDateTime` instance from localized `date` and `time` values.
   * No timezone conversions are performed; the date and time are expected to be in the
   * timezone passed to the `timezoneId` parameter.
   *
   * @param value A `DateTimeParts` object to construct the date-time from
   * @param timezoneId The local timezone the date-time values are in.
   * @returns a new `ElevateDateTime` instance set to the provided date, time, and timezone.
   */
  static fromLocal(value: DateTimeParts, timezoneId: OptionalString): ElevateDateTime {
    return new ElevateDateTime(DateTime.fromISO(`${value.date}T${value.time}`, { zone: timezoneId || 'local' }));
  }

  static fromJSDate(value: Nullable<Date>, timezoneId?: OptionalString): ElevateDateTime {
    return value ? new ElevateDateTime(DateTime.fromJSDate(value).toUTC(), timezoneId) : new ElevateDateTime();
  }

  /**
   * Creates an `ElevateDateTime` instance from UTC `date` and `time` values.
   * Use on `AWSDate`, `AWSTime`, and `AWSDateTime` values returned from the API, to localize
   * to a preferred timezone.
   *
   * If a `timezoneId` is specified, the date-time are converted to this timezone, otherwise left in UTC.
   *
   * @param value A `DateTimeParts` object or an ISO formatted string to construct the `ElevateDateTime` from.
   * @param timezoneId (optional) timezone to convert the date-time to from UTC
   * @returns a new `ElevateDateTime` instance set to the provided date, time, and timezone.
   */
  static fromUTC(value: OptionalString | DateTimeParts, timezoneId?: OptionalString): ElevateDateTime {
    return isOptionalString(value)
      ? new ElevateDateTime(DateTime.fromISO(value ?? '', { zone: 'utc' }), timezoneId)
      : new ElevateDateTime(DateTime.fromISO(`${value.date}T${value.time}`, { zone: 'utc' }), timezoneId);
  }
}

export const getDateTimeFromReference = (
  refISODateTime: string,
  utcTime: string,
  targetDate: OptionalString,
  timezoneId: OptionalString
) => {
  // get current day in the UTC zone
  const currentDate = DateTime.now().toUTC().toISODate();
  // Calibrate the current date based on the timezone and the specific time value.
  // This will roll the actual date forward/backward if we cross the UTC zone boundary
  const currentDateTime = ElevateDateTime.fromUTC({ date: currentDate, time: utcTime }, timezoneId);
  // Calibrate the target date based on the time and timezone; fallback to the current date
  let targetDateTime = ElevateDateTime.fromUTC({ date: targetDate, time: utcTime }, timezoneId);
  if (!targetDateTime.isValid) {
    targetDateTime = currentDateTime;
  }

  // If target and reference are different use the ISO timestamp to get a specific reference date
  const referenceDate = refISODateTime === targetDate ? targetDate : refISODateTime;
  const localizedDateTime = ElevateDateTime.fromUTC({ date: referenceDate, time: utcTime }, timezoneId);

  // if the target date is before current date, roll to the current date
  if (targetDateTime.isBefore(currentDateTime) && currentDateTime.isValid) {
    targetDateTime = currentDateTime.startOfDay;
  }
  // Recalibrate to the target date. This automatically accounts for DST boundaries
  return localizedDateTime.changeDate(targetDateTime);
};

export type BetterToHumanDurationOpts = ToHumanDurationOptions & {
  stripZeroUnits?: 'all' | 'end' | 'none';
  precision?: DurationLikeObject;
  maxUnits?: number;
  smallestUnit?: DurationUnit;
  biggestUnit?: DurationUnit;
};

export function betterHumanDuration(iDuration: Duration, opts: BetterToHumanDurationOpts): string {
  let duration = iDuration.normalize();
  let durationUnits: DurationUnit[] = [];
  const precision = typeof opts.precision == 'object' ? Duration.fromObject(opts.precision) : Duration.fromMillis(0);
  let remainingDuration = duration;
  //list of all available units
  const allUnits: DurationUnit[] = ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'];
  let smallestUnitIndex: OptionalNumber;
  let biggestUnitIndex: OptionalNumber;
  // check if user has specified the smallest unit that should be displayed
  if (opts.smallestUnit) {
    smallestUnitIndex = allUnits.indexOf(opts.smallestUnit);
  }
  // check if user has specified a biggest unit
  if (opts.biggestUnit) {
    biggestUnitIndex = allUnits.indexOf(opts.biggestUnit);
  }
  // use seconds and years as default for smallest and biggest unit
  if (!smallestUnitIndex || !(smallestUnitIndex >= 0 && smallestUnitIndex < allUnits.length))
    smallestUnitIndex = allUnits.indexOf('seconds');
  if (!biggestUnitIndex || !(biggestUnitIndex <= smallestUnitIndex && biggestUnitIndex < allUnits.length))
    biggestUnitIndex = allUnits.indexOf('years');

  for (const unit of allUnits.slice(biggestUnitIndex, smallestUnitIndex + 1)) {
    const durationInUnit = remainingDuration.as(unit);
    if (durationInUnit >= 1) {
      durationUnits.push(unit);
      const tmp = {};
      tmp[unit] = Math.floor(remainingDuration.as(unit));
      remainingDuration = remainingDuration.minus(Duration.fromObject(tmp)).normalize();

      // check if remaining duration is smaller than precision
      if (remainingDuration < precision) {
        // ok, we're allowed to remove the remaining parts and to round the current unit
        break;
      }
    }

    // check if we have already the maximum count of units allowed
    if (opts.maxUnits && durationUnits.length >= opts.maxUnits) {
      break;
    }
  }
  // after gathering of units that shall be displayed has finished, remove the remaining duration to avoid non-integers
  duration = duration.minus(remainingDuration).normalize();
  duration = duration.shiftTo(...durationUnits);
  if (opts.stripZeroUnits == 'all') {
    durationUnits = durationUnits.filter((unit) => duration.get(unit) > 0);
  } else if (opts.stripZeroUnits == 'end') {
    let mayStrip = true;
    durationUnits = durationUnits.reverse().filter((unit /*index*/) => {
      if (!mayStrip) return true;

      if (duration.get(unit) == 0) {
        return false;
      } else {
        mayStrip = false;
      }
      return true;
    });
  }
  return duration.shiftTo(...durationUnits).toHuman();
}
