import type { Nullable } from '@amzn/elevate-data-types';

import type { CollaborationChangeSpec, CollaborationDefinition, MappedParticipant } from './models';
import { ElevateDateTime } from '@/common/date-time-helpers';
import type { CollaboratingParticipant } from '@/components/common/event/models';
import { EventParticipantMode, type SharedParticipantDefinition } from '@/graphql/types';
import type { BaseCollaboration, CollaborationItem, InboundCollaborationItem, OutboundCollaborationItem } from '@/models';
import { encodeToPublicID } from '@/utilities/uuid-encoder';

export function mapParticipants(
  sharedParticipants: Nullable<SharedParticipantDefinition[]>,
  sourceIdToName: Map<string, string>,
  targetIdToName: Map<string, string>
): MappedParticipant[] {
  if (!sharedParticipants) {
    return [];
  }
  return sharedParticipants.map((sharedParticipant) => ({
    sourceParticipantId: sharedParticipant.originParticipantId,
    sourceParticipantName: sourceIdToName.get(sharedParticipant.originParticipantId),
    targetParticipantId: sharedParticipant.targetParticipantId,
    targetParticipantName: targetIdToName.get(sharedParticipant.targetParticipantId),
    mode: sharedParticipant.mode ? EventParticipantMode[sharedParticipant.mode] : undefined,
  }));
}

export function extractParticipantsToMap(
  definitions: CollaborationItem['sharedPSParticipants'],
  myOrgParticipants: CollaborationItem['originParticipants'] | CollaborationItem['targetParticipants'],
  otherOrgParticipants: CollaborationItem['originParticipants'] | CollaborationItem['targetParticipants'],
  collaborationType: 'inbound' | 'outbound',
  participantIdMap: Map<string, CollaboratingParticipant[]>
) {
  for (const def of definitions ?? []) {
    const myOrgParticipantId = collaborationType === 'outbound' ? def.originParticipantId : def.targetParticipantId;
    const otherOrgParticipantId = collaborationType === 'outbound' ? def.targetParticipantId : def.originParticipantId;
    const myOrgParticipant = myOrgParticipants.find((opt) => opt.id === myOrgParticipantId);
    const otherOrgParticipant = otherOrgParticipants.find((opt) => opt.id === otherOrgParticipantId);
    if (!otherOrgParticipant || !myOrgParticipant) continue;
    if (!participantIdMap.has(myOrgParticipantId)) participantIdMap.set(myOrgParticipantId, []);
    participantIdMap.get(myOrgParticipantId)!.push({
      id: otherOrgParticipantId,
      mode: def.mode ? EventParticipantMode[def.mode] : EventParticipantMode.NONE,
      calibrationId: otherOrgParticipant.calibrationConfigID,
      myOrgCalibrationId: myOrgParticipant.calibrationConfigID,
      orgId: otherOrgParticipantId,
    });
  }
}

function processBaseCollaboration(
  item: BaseCollaboration,
  srcParticipantIdToName: Map<string, string>,
  tgtParticipantIdToName: Map<string, string>,
  preferredTimezoneId?: string
) {
  const status: CollaborationDefinition['status'] = item.isActive
    ? 'Active'
    : item.isPendingAuthorization
      ? 'Awaiting acceptance'
      : 'Inactive';
  return {
    status,
    id: item.id,
    filterId: encodeToPublicID(item.id),
    createdAt: ElevateDateTime.fromUTC(item.createdAt, preferredTimezoneId).displayDateTime,
    updatedAt: ElevateDateTime.fromUTC(item.updatedAt, preferredTimezoneId).displayDateTime,
    isActive: item.isActive ?? false,
    isPendingAuthorization: item.isPendingAuthorization ?? false,
    shareBarRaisers: item.barRaisers ?? false,
    shareLoops: !!item.sharedLoopParticipants?.length,
    sharePhoneScreens: !!item.sharedPSParticipants?.length,
    loopParticipants: mapParticipants(item.sharedLoopParticipants, srcParticipantIdToName, tgtParticipantIdToName),
    psParticipants: mapParticipants(item.sharedPSParticipants, srcParticipantIdToName, tgtParticipantIdToName),
    pendingChanges: item.pendingChangeSpec ? (JSON.parse(item.pendingChangeSpec) as CollaborationChangeSpec) : null,
  };
}

export function processInboundCollaboration(
  item: InboundCollaborationItem,
  tgtParticipantMap: Map<string, string>,
  preferredTimezoneId?: string
): CollaborationDefinition {
  const srcParticipantMap = new Map(
    (item.originatingOrg.loopPSConfigs?.items ?? []).map((item) => [item.id, item.name ?? ''])
  );
  return {
    ...processBaseCollaboration(item, srcParticipantMap, tgtParticipantMap, preferredTimezoneId),
    orgId: item.originatingOrg.id,
    filterOrgId: encodeToPublicID(item.originatingOrg.id),
    orgName: item.originatingOrg.name,
    variant: 'inbound',
  };
}

export function processOutboundCollaboration(
  item: OutboundCollaborationItem,
  srcParticipantMap: Map<string, string>,
  preferredTimezoneId?: string
): CollaborationDefinition {
  const tgtParticipantMap = new Map((item.targetOrg.loopPSConfigs?.items ?? []).map((item) => [item.id, item.name ?? '']));
  return {
    ...processBaseCollaboration(item, srcParticipantMap, tgtParticipantMap, preferredTimezoneId),
    orgId: item.targetOrg.id,
    filterOrgId: encodeToPublicID(item.targetOrg.id),
    orgName: item.targetOrg.name,
    variant: 'outbound',
  };
}
